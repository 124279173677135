import { createTabs } from "@/components/Tabs/models";

export const tabs = createTabs(
  {
    image: {
      alt: "",
      view: "02",
      name: "01",
    },
    name: "Гибкие\nконфигурации",
    description: "Настройка конфигурации системы под потребности",
  },

  {
    image: {
      alt: "",
      view: "02",
      name: "02",
    },
    name: "Функциональные\nмодули",
    description: "Разработка прикладных модулей для решения отраслевых задач",
  },

  {
    image: {
      alt: "",
      view: "02",
      name: "03",
    },
    name: "Своевременные\nобновления",
    description: "Централизованная поддержка и обновление модулей до последних версий",
  },

  {
    image: {
      alt: "",
      view: "02",
      name: "04",
    },
    name: "Гибкая\nинтеграция",
    description: "Возможность в короткие сроки интегрироваться с уже действующими системами",
  }
);
