
















import { Component, Vue } from "vue-property-decorator";
import TabsView from "@/components/Tabs/Tabs.vue";
import { tabs } from "@/data/module-solutions";

@Component({
  components: {
    TabsView,
  },
})
export default class ModuleSolutions extends Vue {
  protected tabs = tabs;
}
